<div class="container">
  <div *ngIf="path === '/comparison'">
    Compare: &nbsp;
    <div class="group">
      <input type="text" placeholder="Product ID" [(ngModel)]="productID">
      <button (click)="setProductID()" [disabled]="!productID">Submit</button>
    </div>
  </div>
  <div *ngIf="showToken">
    Flush Token: &nbsp;
    <div class="group">
      <button (click)="flushToken()">Flush</button>
    </div>
  </div>
  <div>
    <a [routerLink]="['/comparison']" *ngIf="path !== '/comparison'">Compare</a>
    <a [routerLink]="['/comparison/lookup']" *ngIf="path !== '/comparison/lookup'">Look Up</a>
  </div>
</div>
