import {Component, Input, OnInit} from '@angular/core';
import {Clipboard} from "@angular/cdk/clipboard";
import {delay} from "rxjs/operators";
import {EMPTY, Observable} from "rxjs";

enum ECopyButtonState {
  DEFAULT,
  COPYING,
  COPIED,
  ERROR,
}


@Component({
  selector: 'app-copy-to-clipboard-button',
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrls: ['./copy-to-clipboard-button.component.scss']
})
export class CopyToClipboardButtonComponent implements OnInit {

  @Input() value?: string;

  state: ECopyButtonState = ECopyButtonState.DEFAULT;
  buttonStates = ECopyButtonState;

  constructor(private clipboard: Clipboard) {
  }

  ngOnInit(): void {
  }

  resetButton() {
    return EMPTY
      .pipe(delay(5000))
      .subscribe({
        complete: () => {
          console.log(this.state);
          this.state = ECopyButtonState.DEFAULT;
        }
      });
  }

  copy() {
    if (this.value) {
      const pending = this.clipboard.beginCopy(this.value);
      let remainingAttempts = 3;
      const attempt = () => {
        console.log(`attempt start`);
        const result = pending.copy();
        this.state = ECopyButtonState.COPYING;
        if (!result && --remainingAttempts) {
          console.log(`attempt - waiting`);
          setTimeout(attempt);
        } else if (!result) {
          console.log(`attempt - failed`);
          this.state = ECopyButtonState.ERROR;
          this.resetButton();
          // Remember to destroy when you're done!
          pending.destroy();
        } else {
          console.log(`attempt - SUCCESS!`);
          this.state = ECopyButtonState.COPIED;
          this.resetButton();
          // Remember to destroy when you're done!
          pending.destroy();
        }
        console.log(`attempt end`);
      };
      attempt();
    }
  }
}
