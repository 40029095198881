import {Injectable} from '@angular/core';
import {AuthenticationService, IAuthenticationResponseBody} from "./authentication.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, forkJoin, merge, Observable, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";

export interface IProductFeature {
  name: string;
  value: string;
  source: string;
}

export interface ICustomData {
  name: string;
  value: string;
}

export interface IProduct {
  snapshot_id: string;
  snapshot_date: string;
  product: string;
  data: IProductFeature[];
}

export interface IIdentity {
  user_id: string;
  user_name: string;
  client_id: string;
  product_profile_id: string;
  custom_data_profile_id: string;
  product_shelf_id: string;
  source_ip: string;
  aws_request_id: string;

}

export interface IComparison {
  product_compare_id: string;
  product_compare_date: string;
  identity: IIdentity;
  language: string;
  products: IProduct[];
  custom_data?: ICustomData[];
}

export interface ISnapshotComparison extends IComparison {
  snapshots: any[];
}

interface IHeader {
  Authorization: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class ComparisonService {
  private _productID: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _comparison: BehaviorSubject<IComparison | null> = new BehaviorSubject<IComparison | null>(null);
  private _productList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _headers: HttpHeaders = new HttpHeaders({'Authorization': ''});
  static cannedProducts = [
    ['AAPL', 'AMZN', 'MSFT'],
    ['ENB', 'CNQ', 'SU'],
    ['NKE', 'LOW', 'F'],
    ['WFC', 'GS', 'MS'],
    ['UNH', 'PFE', 'MRNA'],
    ['CIB300', 'CIB479', 'CCM8077'],
    ['DJT00146', 'BMO158', 'RBF1428'],
    ['LYZ806A', 'DYN404', 'CHO100'],
    ['MMF9274', 'AIM57203', 'BIP216'],
    ['BMO236', 'DYN2110', 'GOC503']
  ];

  productID: Observable<string> = this._productID.asObservable();
  comparison: Observable<IComparison | null> = this._comparison.asObservable();
  productList: Observable<string[]> = this._productList.asObservable();

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
  ) {
    this.authenticationService.token
      .subscribe((token) => {
        if (token) {
          this._headers = new HttpHeaders({'Authorization': token});
        }
      })
  }

  private flushProductID() {
    this._productID.next('');
  }
  private flushComparison() {
    this._comparison.next(null);
  }
  private flushProductList() {
    this._productList.next([]);
  }


  setProductID(productID: string) {
    // TODO: CJ - eventually add some validation here (?)
    this._productID.next(productID);
    this._comparison.next(null);
  }

  setComparison(comparison: IComparison) {
    this._comparison.next(comparison);
  }

  flush() {
    this.flushProductID();
    this.flushComparison();
    this.flushProductList();
  }

  product(productID: string) {
    if (this._headers.get('Authorization')) {
      const observable: Observable<string[]> = new Observable((subscriber) => {
        const list = ComparisonService.cannedProducts.find((productList) => {
          return (productList.indexOf(productID.toUpperCase()) >= 0)
        });
        // console.log(`SERVICE - list: ${list}`);
        const sorted = list?.sort((a: string, b: string) => {
          // console.log(`SERVICE - sorting - A:${a} - B: ${b} - 1: ${(a === productID)}`);
          return (a === productID) ? -1 : 0;
        });
        // console.log(`SERVICE - sorted: ${sorted}`);
        if (sorted) {
          subscriber.next(sorted);
          subscriber.complete();
        } else {
          subscriber.error(`Unable to find the product: "${productID}".`)
        }
      });
      observable
        .subscribe(
          (productList: string[]) => {
            this._productList.next(productList);
          });
      return observable;
    }
    return throwError('Not Authenticated')
  }


  lookupComparison(productComparisonId: string) {
    return this.http.get<IComparison>(
      `${environment.baseUrl}/product-compare/${productComparisonId}`,
      {
        headers: this._headers,
        responseType: "json",
      })
      .pipe(
        switchMap((comparison) => {
          console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
          console.log(comparison);
          console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
          const snapshotRequestArray: Array<Observable<IProduct | IComparison>> = comparison.products.map((product) => {
            return this.http.get<IProduct>(
              `${environment.baseUrl}/product-compare/${productComparisonId}/snapshot/${product.snapshot_id}`,
              {
                headers: this._headers,
                responseType: "json",
              })
          });
          snapshotRequestArray.push(new Observable((subscriber) => {
            subscriber.next(comparison);
            subscriber.complete();
          }));
          return forkJoin(snapshotRequestArray);
        }),
        map((snapshotRequestArray: Array<IProduct | IComparison>) => {
          const comparison: IComparison = snapshotRequestArray.pop() as ISnapshotComparison;
          if (comparison) {
            comparison.products = snapshotRequestArray as IProduct[];
          }
          return comparison;
        })
      )
  }

  compare(products: string[]): Observable<IComparison> {
    this._comparison.next(null);
    if (this._headers.get('Authorization')) {
      return this.http.post<IComparison>(
        `${environment.baseUrl}/product-compare`,
        {
          products,
          language: 'en'
        },
        {
          headers: this._headers,
          responseType: "json",
        }).pipe(
        catchError((err) => {
          if (err.error.message === `Unauthorized`) {
            console.log(`** (compare) Unauthorized`)
            return this.authenticationService.authenticate()
              .pipe(
                mergeMap((responseBody: IAuthenticationResponseBody) => {
                  if (responseBody.token) {
                    this._headers = new HttpHeaders({'Authorization': responseBody.token});
                    return this.compare(products);
                  }
                  return throwError(`Unable to Authenticate`);
                })
              )
          } else {
            throw err;
          }
        }));
    }
    return throwError('Not Authenticated')
  }

  close(productComparisonId: string, productID?: string): Observable<any> {
    if (this._headers.get('Authorization')) {
      return this.http.post(
        `${environment.baseUrl}/product-compare/${productComparisonId}/close`,
        {
          "custom_data": [
            {
              "name": "IA Code",
              "value": "A1S3"
            }
          ]
        },
        {
          headers: this._headers,
          responseType: "json",
        })
        .pipe(
          catchError((err) => {
            console.log(`** (close) Unauthorized`)
            if (err.error.message === `Unauthorized`) {
              return this.authenticationService.authenticate()
                .pipe(
                  mergeMap((responseBody: IAuthenticationResponseBody) => {
                    if (responseBody.token) {
                      console.log(`** (close) AUTHORIZATION TOKEN: ${responseBody.token}`)
                      this._headers = new HttpHeaders({'Authorization': responseBody.token});
                    }
                    return this.close(productComparisonId, productID)
                  })
                )
            } else {
              throw err;
            }
          }));
    }
    return throwError('Not Authenticated')
  }
}
