<button (click)="copy()"
        [disabled]="state !== buttonStates.DEFAULT"
        [ngClass]="{
default: state === buttonStates.DEFAULT,
copying: state === buttonStates.COPYING,
'copied success': state === buttonStates.COPIED,
error: state === buttonStates.ERROR
}">
  <span
    class="nc-icon-glyph lg"
    [ngClass]="{
'design_path-unite': state === buttonStates.DEFAULT,
'spin loader_refresh': state === buttonStates.COPYING,
'ui-1_check-bold': state === buttonStates.COPIED,
'ui-2_alert-circle-': state === buttonStates.ERROR
    }"
  ></span> copy to clipboard
</button>
