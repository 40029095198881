<fieldset>
  <legend>{{title}}</legend>
  <div>
    <div
      class="pseudo-input"
      contenteditable="true"
      [textContent]="userInput"
      (input)="updateValue($event.currentTarget)"></div>
    <ng-content></ng-content>
  </div>
</fieldset>

