import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../service/authentication.service";
import {Router} from "@angular/router";
import {ComparisonService} from "../../service/comparison.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  redirectUrl!:string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private comparisonService: ComparisonService,
  ) {
  }

  ngOnInit(): void {
    console.log(`LOGIN COMPONENT - INIT`)
    this.authenticationService.redirectUrl.subscribe((redirectUrl) => {
      this.redirectUrl = (redirectUrl) ? redirectUrl : '/comparison';
    });
    this.authenticationService.token
      .subscribe((token): Promise<boolean> | void => {
        this.comparisonService.productID
          .subscribe((productID) => {
            if (!productID) this.comparisonService.setProductID('CIB300');
          });
        if (token) return this.router.navigate([this.redirectUrl]);
        this.authenticationService.authenticate()
          .subscribe((response): Promise<boolean> => {
            if (response.token) return this.router.navigate([this.redirectUrl]);
            return Promise.reject('invalid token');
          });
      });
  }

}
