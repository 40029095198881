import {Component} from '@angular/core';
import {ApplicationService} from "./service/application.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ComparisonService} from "./service/comparison.service";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Broadridge';
  isDevMode() {
    return !environment.production;
  }

  constructor(
    private applicationService: ApplicationService,
    private comparisonService: ComparisonService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      const productID = params['productID'];
      if (productID) {
        console.log(`PARAMS - productID: ${productID}`);
        this.comparisonService.setProductID(productID)
      }
    });
  }
}
