import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from './../../environments/environment';
import {tap} from "rxjs/operators";

interface IAuthenticationRequestBody {
  key: string;
  secret: string;
}


export interface IAuthenticationResponseBody {
  token: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _token: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  token: Observable<string | null> = this._token.asObservable();

  private _redirectUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  redirectUrl: Observable<string> = this._redirectUrl.asObservable();

  tempAuthBody: IAuthenticationRequestBody = {
    key: 'key',
    secret: 'secret',
  }


  constructor(
    private http: HttpClient
  ) {
  }

  flushAuth() {
    this._token.next('flush')
  }

  authenticate() {
    return this.http.post<IAuthenticationResponseBody>(`${environment.baseUrl}/authentication`, this.tempAuthBody)
      .pipe(
        tap((response: IAuthenticationResponseBody) => {
          this._token.next(response.token);
        }));
  }

  setRedirectUrl(url: string) {
    this._redirectUrl.next(url)
  }
}
