import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit, OnChanges {

  @Input() value: string | null | undefined;
  @Input() disabled?: boolean = false;
  @Input() id?: string;
  @Input() name?: string;
  @Input() required?: boolean;
  @Input() minlength?: number;
  @Input() maxlength?: number;
  @Input() size?: number;
  @Input() autocomplete?: string;
  @Input() autofocus?: boolean;
  @Input() list?: string;
  @Input() pattern?: RegExp;
  @Input() placeholder?: string;
  @Input() readonly?: boolean;
  @Input() tabindex?: number;
  @Input() title?: string;

  @Output() valueInput: EventEmitter<string> = new EventEmitter<string>();

  private _element: HTMLElement;
  private _pseudoInputElement?: Element | null;

  userInput?: string;

  constructor(
    private elementRef: ElementRef
  ) {
    this._element = elementRef.nativeElement;
  }

  updateValue(target: EventTarget | null) {
    const element = target as HTMLElement;
    console.log(`==========================================`);
    console.log(element);
    console.log(element.innerText);
    this.valueInput.emit(element.innerText);
  }

  setReadonlyState(disabled: boolean) {
    const processed = (!disabled).toString();
    this._pseudoInputElement?.setAttribute('contenteditable', processed);
  }


  ngOnInit(): void {
    this._pseudoInputElement = this._element.querySelector('.pseudo-element');
    if (this.readonly !== undefined) {
      this.setReadonlyState(this.readonly);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.userInput = changes['value'].currentValue;
    }
    if (changes['readonly']) {
      this.setReadonlyState(changes['readonly'].currentValue);
    }
  }


}
