import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class ApplicationService {

  private _page: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  page: Observable<string | null> = this._page.asObservable();

  constructor() { }

}
