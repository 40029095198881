<div class="masthead container">
  <div class="masthead-logo">
    <img src="assets/images/logos/br-logo-blue.svg">
  </div>
  <div class="masthead-application-name">
    <h4>Product Comparison</h4>
  </div>
</div>
<nav>
  <div class="container">
  </div>
</nav>
