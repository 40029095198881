import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn} from "@angular/forms";

export function regExpValidator(regExp: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = !regExp.test(control.value);
    return forbidden ? {forbiddenName: {value: control.value}} : null;
  };
}

@Directive({
  selector: '[app-regex-validator]',
  providers: [{provide: NG_VALIDATORS, useExisting: RegexValidatorDirective, multi: true}]
})

export class RegexValidatorDirective {

  @Input('app-regex-validator') regex!: RegExp;
  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.regex ? regExpValidator(new RegExp(this.regex, 'i'))(control) : null;
  }
}
