import {Component, NgZone, OnInit} from '@angular/core';
import {ComparisonService} from "../../../service/comparison.service";
import {AuthenticationService} from "../../../service/authentication.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-test-harness',
  templateUrl: './test-harness.component.html',
  styleUrls: ['./test-harness.component.scss']
})
export class TestHarnessComponent implements OnInit {

  showToken = false;
  productID: string = '';
  path!: string;

  constructor(
    private comparisonService: ComparisonService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    // @ts-ignore
    window['showFlushToken'] = () => {
      this.ngZone.run(() => this.showToken = !this.showToken)
    };

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    )
      .subscribe((event) =>
      {
        this.path = event.url;
        console.log(event);
      });
  }

  setProductID() {
    console.log(`========================================================`)
    console.log(`NEW LOOKUP - TEST HARNESS`)
    console.log(`========================================================`)
    this.comparisonService.setProductID(this.productID);
  }

  flushToken() {
    this.authenticationService.flushAuth();
  }
}
