import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InputTextComponent } from './forms/input-text/input-text.component';
import { CopyToClipboardButtonComponent } from './components/copy-to-clipboard-button/copy-to-clipboard-button.component';
import { ToastComponent } from './components/toast/toast.component';
import { TestHarnessComponent } from './development/test-harness/test-harness.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { RegexValidatorDirective } from './forms/regex-validator.directive';
import {RouterModule} from "@angular/router";



@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        InputTextComponent,
        CopyToClipboardButtonComponent,
        ToastComponent,
        TestHarnessComponent,
        RegexValidatorDirective
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        InputTextComponent,
        CopyToClipboardButtonComponent,
        TestHarnessComponent,
        RegexValidatorDirective
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
  ]
})
export class AppCommonModule { }
